import React, { createContext, useState, useEffect, useContext } from 'react'
import fetch from 'isomorphic-fetch'
import Client from 'shopify-buy'

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_KEY,
  domain: process.env.GATSBY_SHOPIFY_STORE_URL
}, fetch)

const defaultValues = {
  cart: [],
  loading: false,
  didJustAddToCart: false,
  addVariantToCart: (product, quantity) => {},
  removeLineItem: (checkoutID, lineItemID) => {},
  updateLineItem: (checkoutID, lineItemID, quantity) => {},
  client,
  checkout: { 
    id: '', 
    lineItems: [], 
    webUrl: '',
    subtotalPriceV2: {
      currencyCode: '',
      amount: ''
    }
  }
}

const StoreContext = createContext(defaultValues)
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [cart, setCart] = useState(defaultValues.cart)
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const isBrowser = typeof window !== 'undefined'

  const setCheckoutItem = (checkout) => {
    if (isBrowser) localStorage.setItem(localStorageKey, checkout.id)
    setCheckout(checkout)
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null
      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID)
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          if (isBrowser) localStorage.setItem(localStorageKey, null)
        }
      }
      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }
    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res)
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000)
    })
  }

  const removeLineItem = async (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then((res) => {
      setCheckout(res)
      setLoading(false)
    })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res)
      setLoading(false)
    })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        cart,
        checkout,
        loading,
        didJustAddToCart
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useStore = () => {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error('useStore must be used within StoreContext')
  }

  return context
}

export default useStore