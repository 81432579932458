import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { navigate } from 'gatsby'

interface AuthContextType {
  isAuthenticated: boolean
  accessToken: string | null
  loading: boolean
  login: (token: string) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  accessToken: null,
  loading: true,
  login: () => {},
  logout: () => {},
})

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    const token = isBrowser ? localStorage.getItem('shopifyAccessToken') : null
    if (token) {
      setAccessToken(token)
      setIsAuthenticated(true)
    }
    setLoading(false)
  }, [])

  const login = (token: string) => {
    if (isBrowser) {
      localStorage.setItem('shopifyAccessToken', token)
      setAccessToken(token)
      setIsAuthenticated(true)
      setLoading(false)
      navigate('/account')
    }
  }

  const logout = () => {
    if (isBrowser) {
      localStorage.removeItem('shopifyAccessToken')
      setAccessToken(null)
      setIsAuthenticated(false)
      setLoading(false)
      navigate('/')
    }
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, accessToken, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
