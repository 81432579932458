import React from "react"
import { StoreProvider } from './store-context'

interface CombinedProviderProps {
  children: React.ReactNode;
}

const CombinedProvider: React.FC<CombinedProviderProps> = ({ children }) => {
  return (
    <StoreProvider>{children}</StoreProvider>
  )
}

export default CombinedProvider;