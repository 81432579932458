exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-gift-card-tsx": () => import("./../../../src/pages/gift-card.tsx" /* webpackChunkName: "component---src-pages-gift-card-tsx" */),
  "component---src-pages-guest-purchase-tsx": () => import("./../../../src/pages/guest-purchase.tsx" /* webpackChunkName: "component---src-pages-guest-purchase-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-policies-tsx": () => import("./../../../src/pages/our-policies.tsx" /* webpackChunkName: "component---src-pages-our-policies-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-product-detail-tsx": () => import("./../../../src/pages/product-detail.tsx" /* webpackChunkName: "component---src-pages-product-detail-tsx" */),
  "component---src-pages-returns-tsx": () => import("./../../../src/pages/returns.tsx" /* webpackChunkName: "component---src-pages-returns-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */)
}

