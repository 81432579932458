import React from 'react';
import CombinedProvider from './combined-provider';
import { AuthProvider } from './auth-context';

const RootProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <CombinedProvider>
    <AuthProvider>
      {children}
    </AuthProvider>
  </CombinedProvider>
);

export type RootProviderType = typeof RootProvider;

export default RootProvider;
